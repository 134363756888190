export enum MidnightRange {
	OVER_24_HOURS = 'OVER_24_HOURS',
	BETWEEN_24_HOURS_AND_1_SECOND = 'BETWEEN_24_HOURS_AND_1_SECOND',
	LESS_THAN_1_SECOND = 'LESS_THAN_1_SECOND'
}

export const getMidnightRangeString = (time_range: number): string  => {
	const { hours, minutes, seconds } = getTimeFromMilliseconds(time_range);

	const hours_string = hours > 0 ? `${hours}h ` : '';
	const minutes_string = minutes > 0 ? `${minutes}m ` : '';
	const seconds_string = seconds > 0 ? `${seconds}s ` : '';

	return `${hours_string}${minutes_string}${seconds_string}`;
}

export const getMidnightRange = (time_range: number): MidnightRange => {
	if (time_range < (24 * 60 * 60 * 1000) && time_range > 1000) {
		return MidnightRange.BETWEEN_24_HOURS_AND_1_SECOND;
	} else if (time_range <= 1000) {
		return MidnightRange.LESS_THAN_1_SECOND;
	} else {
		return MidnightRange.OVER_24_HOURS;
	}
}


const getTimeFromMilliseconds = (milliseconds: number) => {
	const getHourFromMilliseconds = (milliseconds: number) => {
		return Math.floor(
			(milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	};

	const getMinutesFromMilliseconds = (milliseconds: number) => {
		return Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
	}

	const getSecondsFromMilliseconds = (milliseconds: number) => {
		return Math.floor((milliseconds % (1000 * 60)) / 1000);
	}

	return {
		hours: getHourFromMilliseconds(milliseconds),
		minutes: getMinutesFromMilliseconds(milliseconds),
		seconds: getSecondsFromMilliseconds(milliseconds)
	}
}
