import { Scarcity } from "../../domain/Scarcity";

export type CloseType =
    'closedWithX'
    | 'closedWithCurtainClick'
    | 'closedWithDrag'
    | 'closedProgrammatically';

export type SheetCloseEvent = {
	readonly closeType: CloseType,
	readonly id: string,
};

export const getScarcityStatus = (scarcity: string) => {
	if (scarcity === '-1') return Scarcity.EXPIRED;
	if (scarcity === '0') return Scarcity.NO_SCARCITY;
	if (scarcity === '1') return Scarcity.TODAY;
	if (scarcity === '2') return Scarcity.TOMORROW;
	if (scarcity === '3') return Scarcity.TWO_TO_FOURTEEN_DAYS;
	else return Scarcity.NO_APPLICABLE
};
