import TrackingService, { ActionName } from './TrackingService.ts';
import type { TeaserContent } from '../../domain/TeaserContent.ts';
import { TeaserType } from '../../domain/TeaserType.ts';
import { getScarcityStatus } from './TrackingPayload.ts';
import { Topic } from './Topic.ts';

export const trackClick = (trackingService: TrackingService, teaser: Element, teaserContent: TeaserContent, teaserType: TeaserType, teaserSize: string) => {
	teaser.addEventListener('click', () => {
		const labelsForChildren: Record<string, string[]> = {};
		if (teaserContent.scarcity !== undefined) {
			labelsForChildren['up_Scarcity'] = [getScarcityStatus(teaserContent.scarcity)];
		}

		const parentLabels = {
			promo_Click: ['UpTeaser'],
			promo_Feature: ['true'],
			up_TeaserSize: [teaserSize],
			...getAggregationFeatureLabels(teaser)
		};

		const featureOrder = getFeatureOrder(teaser);

		sendTeaserFeatureTracking(trackingService, teaserType, Topic.AddActionToEvent, 'clicked', parentLabels, labelsForChildren, ActionName.OPEN, featureOrder);
	});
};
export const trackView = (trackingService: TrackingService, teaser: Element, teaserContent: TeaserContent, teaserType: TeaserType, teaserSize: string) => {
	const labelsForChildren: Record<string, string[]> = {};
	if (teaserContent.scarcity !== undefined) {
		labelsForChildren['up_Scarcity'] = [getScarcityStatus(teaserContent.scarcity)];
	}

	const parentLabels = {
		promo_Feature: ['true'],
		up_TeaserSize: [teaserSize],
		...getAggregationFeatureLabels(teaser)
	};

	const featureOrder = getFeatureOrder(teaser);

	sendTeaserFeatureTracking(trackingService, teaserType, Topic.MergeEvent, 'loaded', parentLabels, labelsForChildren, ActionName.VIEW, featureOrder);
};
export const trackSeen = (trackingService: TrackingService, teaser: Element, teaserContent: TeaserContent, teaserType: TeaserType, teaserSize: string) => {
	const labelsForChildren: Record<string, string[]> = {};
	if (teaserContent.scarcity !== undefined) {
		labelsForChildren['up_Scarcity'] = [getScarcityStatus(teaserContent.scarcity)];
	}

	const parentLabels = {
		promo_Click: ['UpTeaser'],
		promo_Feature: ['true'],
		up_TeaserSize: [teaserSize],
		...getAggregationFeatureLabels(teaser)
	};

	const featureOrder = getFeatureOrder(teaser);

	sendTeaserFeatureTracking(trackingService, teaserType, Topic.AddActionToEvent, 'visible', parentLabels, labelsForChildren, ActionName.SCROLL, featureOrder);
};

const sendTeaserFeatureTracking = (
	trackingService: TrackingService,
	teaserType: TeaserType | string,
	topic: Topic,
	status: string,
	labelsForParent: Record<string, string[]>,
	labelsForChildren: Record<string, string[]>,
	actionName: ActionName,
	featureOrder: number
) => {
	trackingService.sendFeatureTracking(
		topic,
		'UPpromo_Teaser',
		'UPPromoTeaser',
		`UPPromoTeaser${teaserType}`,
		status,
		labelsForParent,
		labelsForChildren,
		actionName,
		featureOrder,
		1
	);
};

const getAggregationFeatureLabels = (container: Element) => {
	const aggregationFeatureLabels = container.parentElement?.parentElement?.getAttribute('data-feature-tracking-labels');

	if (!aggregationFeatureLabels) {
		return {};
	}

	return JSON.parse(aggregationFeatureLabels);
};
const getFeatureOrder = (container: Element) => {
	const dataFeatureOrder = container.parentElement?.parentElement?.getAttribute('data-feature-order');
	if (!dataFeatureOrder) {
		return 1;
	}
	return parseInt(dataFeatureOrder, 10);
};
